import { FileTag, Maybe } from '__generated__/types';

interface IUseFileTagMismatchWarningProps {
  fileTag?: Maybe<FileTag>;
  aiFileTag?: Maybe<FileTag>;
}

interface IUseFileTagMismatchWarningReturn {
  getLabResultMismatchWarningKey: () => string;
  isAiLabResultMismatchWithUser: boolean;
  isUserLabResultMismatchWithAi: boolean;
}

type UseFileTagMismatchWarning = (props: IUseFileTagMismatchWarningProps) => IUseFileTagMismatchWarningReturn;

export const useFileTagMismatchWarning: UseFileTagMismatchWarning = ({ aiFileTag, fileTag }) => {
  const isAiFileTagMismatchWithUser = aiFileTag && fileTag !== aiFileTag;
  const isAiLabResultMismatchWithUser = aiFileTag === FileTag.LAB_RESULT && !!isAiFileTagMismatchWithUser;
  const isUserLabResultMismatchWithAi = fileTag === FileTag.LAB_RESULT && !!isAiFileTagMismatchWithUser;

  const getLabResultMismatchWarningKey = (): string => {
    if (isAiLabResultMismatchWithUser) {
      return 'features.attachments.baseFileDataSection.fileTagMismatchWarning.aiLabResultMismatch';
    }

    if (isUserLabResultMismatchWithAi) {
      return 'features.attachments.baseFileDataSection.fileTagMismatchWarning.userLabResultMismatch';
    }

    return '';
  };

  return {
    isAiLabResultMismatchWithUser,
    isUserLabResultMismatchWithAi,
    getLabResultMismatchWarningKey,
  };
};
